import { useLazyQuery } from '@apollo/client'
import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonThumbnail,
  useIonViewDidEnter,
} from '@ionic/react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom'
import { ModalLayout } from 'src/components'
import { FETCH_ONE_USAGER } from 'src/graphql/usagers.graphql'
import { UsagerEntity } from 'src/models/gql/graphql'
import { checkHealthcareWorker } from 'src/utils/auth'
import UsagersActions from './actions/actions'
import './detail.css'
import UsagersHealth from './health/health'
import UpdateHealth from './health/update-health/UpdateHealth'
import CreateNote from './notes/create-note/create-note'
import UsagersNotes from './notes/notes'
import ViewNote from './notes/view-note/view-note'
import UsagerView from './view/view'

export type Segment = 'fiche' | 'actions' | 'notes' | 'health'
const sexeImages: { [key: string]: string } = {
  Masculin: 'gender-m.svg',
  Feminin: 'gender-f.svg',
  Féminin: 'gender-f.svg',
  'Non genre': 'gender-n.svg',
  'Non genré': 'gender-n.svg',
  'Non spécifié': 'gender-n.svg',
}

const DetailUsager: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { name } = useParams<{ name: string }>()
  const location = useLocation()
  const [segment, setSegment] = useState<Segment>()
  const [detailUsager, setDetailUsager] = useState<
    UsagerEntity | null | undefined
  >({})
  const [viewMode, setViewMode] = useState(false)
  const handleSegmentChange = (event: CustomEvent): void => {
    history.replace(`/main/usagers/view/${id}/${event.detail.value}`)
    setSegment(event.detail.value)
  }

  const [load, { data, loading }] = useLazyQuery(FETCH_ONE_USAGER, {
    variables: { id },
  })

  useEffect(() => {
    const activated = location.pathname.split('/')[5] as Segment
    setSegment(activated)

    if (data?.usager) {
      setDetailUsager(data.usager?.data)
    }
  }, [location, data, setDetailUsager, name, viewMode])

  const history = useHistory()

  const handleBack = (): void => {
    history.push(`/main/usagers`)
  }

  useIonViewDidEnter(async () => {
    await load()
  })
  const onReload = async () => {
    await load()
  }

  return (
    <IonPage>
      <ModalLayout title="Profil usager" onBack={handleBack}>
        <IonRow className="ion-justify-content-center ion-align-items-center wrap-profil">
          <div className="profil-picto">
            {detailUsager?.attributes?.sexe && (
              <IonThumbnail slot="start">
                <img
                  alt="Silhouette of a person's head"
                  src={`/assets/images/${
                    detailUsager?.attributes?.sexe
                      ? sexeImages[detailUsager?.attributes?.sexe]
                      : 'gender-n.svg'
                  }`}
                />
              </IonThumbnail>
            )}
          </div>

          <IonText className="profil-name">
            {detailUsager?.attributes?.firstname}{' '}
            {detailUsager?.attributes?.lastname}
          </IonText>
        </IonRow>

        <IonGrid className="profilusager ion-padding-horizontal">
          <IonRow>
            <IonCol size="6">
              <IonItem>
                <IonText className="" color="primary">
                  <svg className="feather medium">
                    <use href="/assets/icon/feather-sprite.svg#phone" />
                  </svg>
                </IonText>
                &nbsp;
                <IonLabel>{detailUsager?.attributes?.phone || '-'}</IonLabel>
              </IonItem>
            </IonCol>
            <IonCol size="6">
              <IonItem>
                <IonText className="" color="primary">
                  <svg className="feather medium">
                    <use href="/assets/icon/feather-sprite.svg#folder-minus" />
                  </svg>
                </IonText>
                &nbsp;
                <IonLabel>
                  {detailUsager?.attributes?.famillyCode
                    ? `Code famille: ${detailUsager?.attributes?.famillyCode}`
                    : '-'}
                </IonLabel>
              </IonItem>
            </IonCol>
            <IonCol size="6">
              <IonItem>
                <IonText className="" color="primary">
                  <svg className="feather medium">
                    <use href="/assets/icon/feather-sprite.svg#map-pin" />
                  </svg>
                </IonText>
                &nbsp;
                <IonLabel>
                  {detailUsager?.attributes?.addressCity || '-'}
                </IonLabel>
              </IonItem>
            </IonCol>
            <IonCol size="6">
              <IonItem>
                <IonText className="" color="primary">
                  <svg className="feather medium ">
                    <use href="/assets/icon/feather-sprite.svg#calendar" />
                  </svg>
                </IonText>
                &nbsp;
                <IonLabel>
                  <IonLabel>
                    {`${detailUsager?.attributes?.age || '-'} ${
                      detailUsager?.attributes?.age &&
                      detailUsager?.attributes?.age > 1
                        ? 'ans'
                        : 'an'
                    } ` || '-'}
                    {' • '}
                    {detailUsager?.attributes?.birthDate
                      ? format(
                          new Date(detailUsager?.attributes?.birthDate),
                          'dd/MM/yyyy',
                        )
                      : '-'}
                  </IonLabel>
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid className="fiche-usager">
          <IonSegment
            className="ficheusager-tabs"
            value={segment}
            onIonChange={handleSegmentChange}
          >
            <IonSegmentButton value="fiche">Fiche usager</IonSegmentButton>
            <IonSegmentButton value="actions">Actions</IonSegmentButton>
            <IonSegmentButton value="notes">Notes</IonSegmentButton>
            {checkHealthcareWorker() && (
              <IonSegmentButton value="health">Santé</IonSegmentButton>
            )}
          </IonSegment>
        </IonGrid>
        <Route
          exact
          path={`/main/usagers/view/${id}`}
          render={() => <Redirect to={`/main/usagers/view/${id}/fiche`} />}
        />
        <Route
          path="/main/usagers/view/:id/fiche"
          render={() => (
            <UsagerView
              detailUsager={detailUsager}
              viewMode
              onReload={onReload}
            />
          )}
          exact
        />
        <Route
          path="/main/:tab(usagers)/view/:id/notes"
          component={UsagersNotes}
          exact
        />
        <Route
          path="/main/:tab(usagers)/view/:id/notes/create"
          component={CreateNote}
          exact
        />
        <Route
          path="/main/:tab(usagers)/view/:id/notes/view/:noteId"
          component={ViewNote}
          exact
        />
        <Route
          path="/main/:tab(usagers)/view/:id/actions"
          component={UsagersActions}
          exact
        />

        <Route
          path="/main/:tab(usagers)/view/:id/health"
          component={UsagersHealth}
          exact
        />

        <Route
          path="/main/:tab(usagers)/view/:id/health/update/:healthId"
          component={UpdateHealth}
          exact
        />
      </ModalLayout>
    </IonPage>
  )
}

export default DetailUsager
