/* eslint-disable camelcase */
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonText,
  IonThumbnail,
  useIonToast,
} from '@ionic/react'
import { ellipse } from 'ionicons/icons'
import { FC, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { UsagerEntity } from 'src/models/gql/graphql'
import { setStepThree } from 'src/stores/slices/ficherActionsSlices'
import { RootState } from 'src/stores/store'
import { formatDate } from 'src/utils'
import './UsagerListItem.css'

const sexeImages: { [key: string]: string } = {
  Masculin: 'sex-m.svg',
  Feminin: 'sex-f.svg',
  Féminin: 'sex-f.svg',
  'Non genre': 'sex-n.svg',
  'Non genré': 'sex-n.svg',
  Non_specifie: 'sex-n.svg',
}

export interface IUsagerListItemProps {
  usager: UsagerEntity
  isAction?: boolean
  isSelected?: boolean
  isUsagerDefault?: boolean
  isFocus?: boolean
  maxi?: number
}

const UsagerListItem: FC<IUsagerListItemProps> = ({
  usager,
  isAction,
  isSelected,
  isUsagerDefault,
  isFocus,
  maxi,
}: IUsagerListItemProps) => {
  const history = useHistory()
  const [idClickFocus, setIdFocus] = useState<string>('')
  const { step_three } = useSelector((state: RootState) => state.ficheActions)
  const dispatch = useDispatch()

  const [present] = useIonToast()

  const presentToast = useCallback(() => {
    present({
      message: 'Désolé, vous avez sélectionné un usager',
      duration: 4000,
      position: 'bottom',
    })
  }, [present])

  const presentToastMaxi = useCallback(
    (id: number) => {
      present({
        message: `Vous devez sélectionner ${id} usager${
          id > 1 ? 's' : ''
        } maximum`,
        duration: 4000,
        position: 'bottom',
      })
    },
    [present],
  )

  const viewUsagers = (
    event: React.MouseEvent<HTMLIonItemElement, MouseEvent>,
    usagera: UsagerEntity,
    id?: string,
  ): void => {
    setIdFocus(id?.toString() || '')
    if (id && !isAction && !isSelected) {
      history.replace(`/main/usagers/view/${id}/fiche`)
    }

    if (isUsagerDefault) {
      presentToast()
    } else if (!isSelected && isAction && !isUsagerDefault) {
      if (!maxi) {
        setTimeout(() => {
          dispatch(setStepThree(usagera))
        }, 250)
      }

      if (maxi && maxi > step_three.length) {
        setTimeout(() => {
          dispatch(setStepThree(usagera))
        }, 250)
      } else if (maxi) {
        presentToastMaxi(maxi)
      }
    }
    setTimeout(() => {
      setIdFocus('')
    }, 250)
    event.preventDefault()
  }
  /* const checkUsagerSelected = (id: string): boolean => {
    const checked =
      step_three.length > 0 &&
      step_three.filter((res) => {
        return res.id === id
      })

    return (checked as UsagerEntity[]).length > 0
  } */

  return (
    <IonItem
      className={`usager_list_item ${
        isFocus && usager.id && idClickFocus === usager?.id.toString()
          ? 'selected'
          : ''
      }`}
      onClick={(e) => viewUsagers(e, usager, usager?.id || '')}
    >
      <IonThumbnail slot="start" className="usager-picto">
        <img
          alt="sexe"
          src={`/assets/images/${
            usager?.attributes?.sexe
              ? sexeImages[usager?.attributes?.sexe]
              : 'sex-n.svg'
          }`}
        />
      </IonThumbnail>
      <div className="ion-justify-content-between liste-usagers">
        <div className="liste-usagers--left">
          <div className="liste-usagers--inner">
            <IonLabel>
              <h3>
                {usager?.attributes?.firstname} {usager?.attributes?.lastname}
              </h3>
            </IonLabel>
          </div>
          <div className="age_contents ion-align-items-center">
            <IonIcon className="picto-age" />
            <IonText>
              {usager?.attributes?.age}{' '}
              {usager?.attributes?.age && usager?.attributes?.age > 1
                ? 'ans'
                : 'an'}
            </IonText>
            <IonIcon className="space" icon={ellipse} />
            <IonText>
              {usager?.attributes?.birthDate
                ? formatDate(usager?.attributes?.birthDate)
                : '-'}
            </IonText>
          </div>
        </div>
        <div className="liste-usagers--right">
          <div className="liste-usagers--inner top">
            <svg className="feather small">
              <use href="/assets/icon/feather-sprite.svg#phone" />
            </svg>
            <IonText className="inner-text">
              {usager?.attributes?.phone || '-'}
            </IonText>
          </div>
          <div className="liste-usagers--inner">
            <svg className="feather small">
              <use href="/assets/icon/feather-sprite.svg#map-pin" />
            </svg>
            <IonText className="inner-text">
              {usager?.attributes?.addressCity || '-'}
            </IonText>
          </div>
        </div>
      </div>
    </IonItem>
  )
}

export default UsagerListItem
