import { graphql } from 'src/models/gql';


export const CREATE_NOTE_MUTATION = graphql(/* GraphQL */ `
  mutation createUsagerNote(
    $data: UsagerNoteInput!
  ) {
    createUsagerNote(data: $data ) {
      data {id}
    }
}
`);

export const UPDATE_NOTE_MUTATION =  graphql(/* GraphQL */ `
  mutation updateUsagerNote(
    $id: ID!
    $data: UsagerNoteInput!
  ) {
    updateUsagerNote(
      id: $id
      data: $data
    ) {
      data {
        id
      }
    }
}
`);

export const DELETE_NOTE_MUTATION =  graphql(/* GraphQL */ `
  mutation deleteUsagerNote(
    $id: ID!
  ) {
    deleteUsagerNote(
      id: $id
    ) {
      data {
        id
      }
    }
}
`);

export const LIST_TYPE_NOTES = graphql(/* GraphQL */ `
query noteTypes(
    $filters: NoteTypeFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    noteTypes(
      filters: $filters
      pagination: $pagination
      sort: $sort
    ) {
      data {
        id,
        attributes {
          name
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`);

export const LIST_NOTES = graphql(/* GraphQL */ `
  query listUsagerNotes(
    $filters: UsagerNoteFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    usagerNotes(
      filters: $filters
      pagination: $pagination
      sort: $sort
    ) {
    data {
      id,
      attributes {
        createdAt
        noteDate,
        noteType {
          data {
            id
            attributes {
              name
            }
          }
        }
        author {
          data {
            id
            attributes {
              firstname
              lastname
            }
          }
        }
        description
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
  }
`);

export const FETCH_ONE_NOTE = graphql(/* GraphQL */ `
  query fetchUsagerNoteById($id: ID!) {
    usagerNote(id: $id) {
      data {
        id
        attributes {
          noteType {
            data {
              id
              attributes {
                name
              }
            }
          }
          author {
            data {
              id
              attributes {
                firstname
                lastname
              }
            }
          }
          description
          createdAt
          updatedAt
          noteDate
        }
      }
    }
  }
`)