/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'


const container = document.getElementById('root')
const root = createRoot(container!)
const {hostname} = new URL(process.env.REACT_APP_GRAPHQL_ENDPOINT!)

root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if ('serviceWorker'  in navigator) {
  navigator.serviceWorker.register('./firebase-messaging-sw.js')
   .then(function(registration) {
    console.log('Registration was successful: ', registration)
   })
   .catch(function(e) {
    console.error('Registration has filed', e)
   })
 }
