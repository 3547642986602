/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  InputChangeEventDetail,
  IonButton,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonImg,
  IonInput,
  IonItem,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  useIonLoading,
  useIonToast,
} from '@ionic/react'
// eslint-disable-next-line import/no-extraneous-dependencies
import DateInput from 'date-input'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { format } from 'date-fns'
import { useHistory } from 'react-router'
import { ConfirmModal } from 'src/components'
import {
  CREATE_USAGER_MUTATION,
  LIST_USAGERS,
  UPDATE_USAGER_MUTATION,
} from 'src/graphql/usagers.graphql'
import {
  GET_ADMINISTRATIVE,
  GET_FAMILY,
  GET_PROFESSIONAL_SITUATIONS,
  GET_REMUNERATIONS_TYPE,
} from 'src/graphql/utilities.graphql'
import {
  // eslint-disable-next-line camelcase
  Enum_Usager_Sexe,
  Maybe,
  UsagerEntity,
} from 'src/models/gql/graphql'
import { formatUserName, getOrgId } from 'src/utils'
import { nationality } from 'src/utils/nationality'

import HistoryButton from '../HistoryButton/HistoryButton'
import IonicSearchAddressInput from '../IonicSearchAddressInput/IonicSearchAddressInput'
import { HistoryModal } from '../Modals'
import Selection from '../Selection/Selection'

interface FormValues {
  firstname: Maybe<string | undefined>
  lastname: Maybe<string | undefined>
  chief?: Maybe<boolean>
  birthDate: Maybe<string | undefined>
  age?: number
  familyStatus?: Maybe<string | undefined>
  nationality?: Maybe<string | undefined>
  phone?: Maybe<string | undefined>
  email?: Maybe<string | undefined>
  addressLine?: Maybe<string | undefined>
  addressCity?: Maybe<string | undefined>
  addressZipcode?: Maybe<string | undefined>
  addressCountry?: Maybe<string | undefined>
  positionAdministrative?: Maybe<string | undefined>
  resources?: Maybe<string | undefined>
  // eslint-disable-next-line camelcase
  sexe?: Maybe<Enum_Usager_Sexe>
  resourcesPrice?: Maybe<number>
  famillyCode?: Maybe<string | undefined>
  professionalSituation?: Maybe<string | undefined>
}

interface UsagerProps {
  isCreate?: boolean
  detailUsager?: UsagerEntity
  viewMode?: boolean
  onReload?: () => void
}

interface FormErrors {
  [key: string]: string
}

const Usagers: React.FC<UsagerProps> = (props) => {
  const { isCreate, detailUsager, viewMode, onReload } = props
  const [isViewMode, setIsViewMode] = useState(false)

  const [currentHistoryData, loadHistoryFor] = useState<{
    field: string
    label: string
    data?: IdNameEntity[]
  }>()
  const [modalSuccess, showModalSuccess] = useState(false)
  const [historyModalOpened, openModalHistory] = useState(false)
  const [
    loadChiefUsagers,
    { loading: usagersLoading, error: usagersError, data: usagersData },
  ] = useLazyQuery(LIST_USAGERS)
  const [
    loadAdministratives,
    { loading: adminLoading, error: adminError, data: adminData },
  ] = useLazyQuery(GET_ADMINISTRATIVE, {
    variables: {
      filters: { organization: { id: { eq: getOrgId() } } },
      pagination: { limit: 500, start: 0 },
    },
  })
  const [
    loadProfesionalSituations,
    { loading: spLoading, error: spError, data: professionalSituations },
  ] = useLazyQuery(GET_PROFESSIONAL_SITUATIONS, {
    variables: {
      filters: { organization: { id: { eq: getOrgId() } } },
      pagination: { limit: 500, start: 0 },
    },
  })
  const [
    loadRemunerations,
    {
      loading: resourcesLoading,
      error: resourcesError,
      data: remunerationTypes,
    },
  ] = useLazyQuery(GET_REMUNERATIONS_TYPE)
  const [
    loadFamilies,
    { loading: familiesLoading, error: familiesError, data: familiesData },
  ] = useLazyQuery(GET_FAMILY)

  const [formValues, setFormValues] = useState<FormValues>({
    firstname: '',
    lastname: '',
    birthDate: '',
    chief: false,
    age: 0,
    familyStatus: '',
    famillyCode: '',
    nationality: '',
    phone: '',
    email: '',
    addressLine: '',
    addressCity: '',
    addressZipcode: '',
    addressCountry: '',
    positionAdministrative: '',
    resources: '',
    resourcesPrice: null,
    professionalSituation: '',
  })
  const initializeValues = (item: UsagerEntity) => {
    const initialValues = {
      firstname: item?.attributes?.firstname,
      lastname: item?.attributes?.lastname,
      birthDate: item?.attributes?.birthDate,
      chief: item?.attributes?.chief || false,
      age: item?.attributes?.age || 0,
      nationality: item?.attributes?.nationality,
      phone: item?.attributes?.phone,
      email: item?.attributes?.email,
      addressLine: item?.attributes?.addressLine,
      positionAdministrative:
        item?.attributes?.positionAdministrative?.data?.id,
      resources: item?.attributes?.resources?.data?.id,
      sexe: item?.attributes?.sexe || Enum_Usager_Sexe.NonGenre,
      famillyCode: item?.attributes?.famillyCode || '',
      familyStatus: item?.attributes?.familyStatus?.data?.id,
      resourcesPrice: item?.attributes?.resourcesPrice || null,
      professionalSituation: item?.attributes?.professionalSituation?.data?.id,
    }
    setFormValues(initialValues)
  }
  useEffect(() => {
    if (detailUsager) {
      initializeValues(detailUsager)
    }
    if (viewMode) {
      setIsViewMode(viewMode)
    }

    translatePlaceholder()
  }, [detailUsager, adminData, familiesData, viewMode])

  useEffect(() => {
    if (!isViewMode) {
      setTimeout(translatePlaceholder, 500)
    }
  }, [isViewMode])

  const history = useHistory()
  const [present] = useIonToast()

  const presentToast = useCallback(() => {
    present({
      message:
        "Une erreur est survenue lors de l'operation. Veuillez nous excuser pour ce désagrément. Pouvez-vous réessayer plus tard ou contacter notre équipe de support pour obtenir de l'aide supplémentaire",
      duration: 6000,
      position: 'bottom',
    })
  }, [present])

  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const [presentLoading, dismissLoading] = useIonLoading()
  const [
    createUsager,
    { loading: createLoading, error: errorCreate },
  ] = useMutation(CREATE_USAGER_MUTATION)
  const [
    updateUser,
    { loading: updateLoading, error: errorUpdate },
  ] = useMutation(UPDATE_USAGER_MUTATION)

  useEffect(() => {
    if (createLoading || updateLoading) {
      presentLoading()
    } else {
      dismissLoading()
    }
    if (errorCreate || errorUpdate) {
      presentToast()
    }
    return () => {
      dismissLoading()
    }
  }, [
    createLoading,
    updateLoading,
    dismissLoading,
    presentLoading,
    errorCreate,
    presentToast,
    errorUpdate,
  ])

  const creationUsagers = async (isFrom: string): Promise<void> => {
    try {
      const result = await createUsager({
        variables: {
          data: {
            firstname: formValues.firstname,
            lastname: formValues.lastname,
            age: formValues.age ? parseInt(formValues.age.toString(), 10) : 0,
            chief: formValues.chief,
            sexe: formValues.sexe || Enum_Usager_Sexe.NonGenre,
            ...(formValues.birthDate && { birthDate: formValues.birthDate }),
            organization: getOrgId(),
          },
        },
      })

      const { data } = result
      if (data?.createUsager?.data?.id) {
        if (isFrom === 'usagers_actions_fiche') {
          history.replace(
            `/view/${data?.createUsager?.data?.id}/actions/create`,
          )
        } else {
          //  `/main/usagers/view/${data?.createUsager?.data?.id}/actions`,
          history.replace(
            `/main/usagers/view/${data?.createUsager?.data?.id}/fiche`,
          )
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error #1', e)
      throw e
    }
  }

  const closeModal = (): void => {
    showModalSuccess(!modalSuccess)
  }
  const handleChangeAddress = (address: IAddress) => {
    setFormValues((prevValues) => ({ ...prevValues, ...address }))
  }

  const handleDateChange = (event: any) => {
    let age: number
    const dateActuelle = new Date()
    if (event.length === 10) {
      const difference = dateActuelle.getTime() - new Date(event).getTime()
      age = Math.floor(difference / (1000 * 60 * 60 * 24 * 365.25))
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      age,
      birthDate: event !== '--' ? (event === '--' ? '' : event) : '',
    }))
  }

  const handleIonChange = (
    event: CustomEvent<InputChangeEventDetail>,
  ): void => {
    handleChange('age')(event)
  }

  const handleChange = (key: keyof FormValues) => (
    event: CustomEvent<InputChangeEventDetail>,
  ) => {
    const { value } = event.detail
    setFormValues((prevValues) => ({ ...prevValues, [key]: value }))

    if (key === 'birthDate') {
      const dateNaissance = new Date(`${value}`)

      const dateActuelle = new Date()
      const difference = dateActuelle.getTime() - dateNaissance.getTime()
      const age = Math.floor(difference / (1000 * 60 * 60 * 24 * 365.25))
      setFormValues((prevValues) => ({
        ...prevValues,
        age,
        birthDate: format(dateNaissance, 'yyyy-MM-dd'),
      }))
    }

    if (key === 'lastname') {
      setFormValues((prevValues) => ({
        ...prevValues,
        lastname: value?.toString().toUpperCase(),
      }))
    }
    if (key === 'firstname') {
      setFormValues((prevValues) => ({
        ...prevValues,
        firstname: `${value
          ?.toString()
          .charAt(0)
          .toUpperCase()}${value?.toString().slice(1)}`,
      }))
    }

    if (key === 'phone') {
      const formattedValue = value?.toString().replace(/(\d{2})/g, '$1 ')
      setFormValues((prevValues) => ({ ...prevValues, phone: formattedValue }))
    }
    if (key === 'resourcesPrice') {
      setFormValues((prevValues) => ({
        ...prevValues,
        resourcesPrice: value ? parseFloat(value.toString()) : 0,
      }))
    }
  }

  const handleCheckboxChange = (event: CustomEvent): void => {
    const { checked } = event.detail
    setFormValues((prevValues) => ({
      ...prevValues,
      chief: checked,
      famillyCode: '',
    }))
  }

  const handleSubmit = (
    event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
    isFrom: string,
  ): void => {
    const errors: FormErrors = {}
    // Vérifier si les champs sont vides
    if (!formValues.firstname || formValues.firstname?.trim() === '') {
      errors.firstname = 'Ce champ est obligatoire'
    }
    if (!formValues.lastname || formValues.lastname.trim() === '') {
      errors.lastname = 'Ce champ est obligatoire'
    }
    if (!formValues.birthDate && !formValues.age) {
      errors.birthDate = 'Ce champ est obligatoire'
    }

    // Mettre à jour l'état des erreurs
    setFormErrors(errors)
    // Si le formulaire est valide, soumettre le formulaire
    if (Object.keys(errors).length === 0) {
      creationUsagers(isFrom)
    }
    event.preventDefault()
  }

  const handleUpdate = async (): Promise<void> => {
    try {
      const data = await updateUser({
        variables: {
          id: detailUsager?.id || '',
          data: {
            ...formValues,
          },
        },
      })
      if (!data.errors) {
        setIsViewMode(true)
        closeModal()
      }
      if (data.data?.updateUsager?.data?.attributes) {
        initializeValues(data.data?.updateUsager?.data)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('e', e)
    }
  }

  const clickUpdate = async (
    event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
  ): Promise<void> => {
    if (isViewMode) {
      setIsViewMode(false)
    } else {
      closeModal()
    }

    event.preventDefault()
  }

  const viewHistory = (field: string, label: string, data?: IdNameEntity[]) => {
    openModalHistory(true)
    loadHistoryFor({ field, data, label })
  }
  useEffect(() => {
    const load = async () => {
      await loadAdministratives()
      await loadProfesionalSituations()
      await loadFamilies({
        variables: { pagination: { limit: 500, start: 0 }, sort: ['name:ASC'] },
      })
      await loadRemunerations({
        variables: { pagination: { limit: 100, start: 0 } },
      })
      await loadChiefUsagers({
        variables: {
          pagination: { limit: 500, start: 0 },
          filters: {
            and: [
              {
                chief: { eq: true },
              },
              {
                famillyCode: { null: false },
              },
              {
                organization: { id: { eq: getOrgId() } },
              },
            ],
          },
        },
      })
      translatePlaceholder()
    }
    load()
  }, [])

  const translatePlaceholder = (): void => {
    const undefinedDayElement = (document.getElementById(
      'undefined-day',
    ) as unknown) as any

    const undefinedYearElement = (document.getElementById(
      'undefined-year',
    ) as unknown) as any
    if (undefinedDayElement) {
      undefinedDayElement.placeholder = 'jj'
      undefinedYearElement.placeholder = 'aaaa'
    }
  }

  const convertDate = (date: string): string => {
    const parts: string[] = date.split('-')
    const day: string = parts[2]
    const month: string = parts[1]
    const year: string = parts[0]
    return `${day}-${month}-${year}`
  }
  console.log('formValues.birthdate', formValues?.birthDate)
  return (
    <UsagersWrapper className={!isViewMode ? 'create__usager' : ''}>
      <div className={!isViewMode ? 'profiles' : 'profiles view-mode'}>
        <IonRow>
          <IonCol>
            {!isCreate && (
              <IonItem className="ion-align-items-center">
                <IonImg
                  className="profiles--image"
                  src="/assets/images/usagers.svg"
                  alt="profil"
                />
                <h3 className="profile-title" color="primary">
                  Profil usager
                </h3>
              </IonItem>
            )}
            <IonItem>
              <IonInput
                type="text"
                label="Nom"
                disabled={isViewMode}
                labelPlacement="floating"
                value={formValues.lastname}
                onIonChange={handleChange('lastname')}
              />
            </IonItem>
            {formErrors.lastname && formValues.lastname === '' && (
              <IonText color="danger">{formErrors.lastname}</IonText>
            )}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonInput
              label="Prénom"
              disabled={isViewMode}
              labelPlacement="floating"
              type="text"
              value={formValues.firstname}
              onIonChange={handleChange('firstname')}
            />
            {formErrors.firstname && (
              <IonText color="danger">{formErrors.firstname}</IonText>
            )}
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="7">
            {isViewMode ? (
              <IonInput
                label="Date de naissance"
                disabled={isViewMode}
                labelPlacement="floating"
                value={
                  formValues?.birthDate
                    ? convertDate(formValues?.birthDate)
                    : '--/--/--'
                }
              />
            ) : (
              <>
                <span className="label-text-wrapper dob-label">
                  Date de naissance
                </span>
                <DateInput
                  className="dob-input"
                  shouldValidate={false}
                  // invalidError="C'est une date invalide"
                  onChange={handleDateChange}
                  value={formValues.birthDate ? formValues.birthDate : ''}
                />
              </>
            )}

            {formErrors.birthDate && !formValues.age && (
              <IonText color="danger">{formErrors.birthDate}</IonText>
            )}
          </IonCol>
          <IonCol size="1">
            <p className="ou hide">OU</p>
          </IonCol>
          <IonCol size="4">
            <IonItem>
              <IonInput
                className={formValues.birthDate ? 'age disabled' : 'age'}
                type={isViewMode ? 'text' : 'number'}
                value={
                  !isViewMode
                    ? formValues.age
                    : `${formValues.age}  ${
                        formValues.age && formValues?.age > 1 ? 'ans' : 'an'
                      }`
                }
                label="Âge"
                disabled={!!formValues.birthDate || isViewMode}
                onIonInput={handleIonChange}
              />
            </IonItem>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <IonText className="label-select">
              {isViewMode ? 'Sexe' : 'Choisir le sexe'}
            </IonText>
            <IonItem>
              <IonSelect
                mode="md"
                interface="popover"
                fill="outline"
                disabled={isViewMode}
                value={formValues.sexe}
                onIonChange={handleChange('sexe')}
                aria-label="Sexe"
                placeholder={isViewMode ? 'Sexe' : 'Choisir le sexe'}
              >
                <IonSelectOption value={Enum_Usager_Sexe.NonGenre}>
                  Non genré
                </IonSelectOption>
                <IonSelectOption value={Enum_Usager_Sexe.Feminin}>
                  Féminin
                </IonSelectOption>
                <IonSelectOption value={Enum_Usager_Sexe.Masculin}>
                  {Enum_Usager_Sexe.Masculin}
                </IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
        </IonRow>

        {!isCreate && (
          <IonRow>
            <IonCol>
              <IonText className="label-select">Situation familiale </IonText>
              {familiesData && (
                <Selection
                  name="familyStatus"
                  onChange={handleChange('familyStatus')}
                  mode="md"
                  fill="outline"
                  value={formValues.familyStatus}
                  aria-label="Situation familiale "
                  placeholder={
                    isViewMode && !formValues.familyStatus
                      ? '-'
                      : 'Situation familiale'
                  }
                  disabled={isViewMode}
                  items={familiesData.familyStatuses?.data?.map((i) => ({
                    id: i.id,
                    name: i.attributes?.name,
                  }))}
                />
              )}
              {!isViewMode && (
                <HistoryButton
                  onClick={(e) =>
                    viewHistory(
                      'familyStatus',
                      'Situation familiale',
                      familiesData?.familyStatuses?.data?.map((i) => ({
                        id: i.id || '',
                        name: i.attributes?.name || '',
                      })),
                    )
                  }
                />
              )}
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol>
            <IonItem>
              <IonCheckbox
                justify="start"
                checked={formValues.chief === true}
                onIonChange={handleCheckboxChange}
                labelPlacement="end"
                disabled={isViewMode}
              >
                L’usager est un chef de famille
              </IonCheckbox>
            </IonItem>
            {formErrors.chief && (
              <IonText color="danger">{formErrors.chief}</IonText>
            )}
          </IonCol>
        </IonRow>

        {!isCreate && !isViewMode && formValues.chief !== true && (
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonText className="label-select">Chef de famille</IonText>
                <Selection
                  name="famillyCode"
                  onChange={handleChange('famillyCode')}
                  mode="md"
                  fill="outline"
                  value={formValues.famillyCode}
                  aria-label="Chef de famille"
                  autocomplete
                  placeholder={
                    isViewMode && !formValues.famillyCode ? '-' : 'Sélectionner'
                  }
                  disabled={isViewMode}
                  items={usagersData?.usagers?.data?.map((usager) => ({
                    id: usager?.attributes?.famillyCode,
                    name: formatUserName({
                      firstname: usager?.attributes?.firstname,
                      lastname: usager?.attributes?.lastname,
                    }),
                  }))}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}

        {formValues.famillyCode && (
          <IonRow>
            <IonCol>
              <IonText className="label-select">Code famille</IonText>
              <IonText
                className={isViewMode ? '' : `ion-padding-horizontal`}
                color="primary"
              >
                {formValues.famillyCode}
              </IonText>
            </IonCol>
          </IonRow>
        )}

        {!isCreate && (
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonText className="label-select">Nationalité</IonText>
                <Selection
                  name="nationality"
                  onChange={handleChange('nationality')}
                  mode="md"
                  fill="outline"
                  value={formValues.nationality}
                  aria-label="Nationalité"
                  autocomplete
                  placeholder={
                    isViewMode && !formValues.nationality
                      ? '-'
                      : 'Sélectionner la Nationalité'
                  }
                  disabled={isViewMode}
                  items={nationality.map((i) => ({ id: i, name: i }))}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </div>
      {!isCreate && (
        <>
          <div className={!isViewMode ? 'coordonate' : 'coordonate view-mode'}>
            <IonRow>
              <IonCol>
                <IonItem className="ion-align-items-center">
                  <IonImg
                    className="profiles--image"
                    src="/assets/images/box.svg"
                    alt="profil"
                  />
                  <h3 className="profile-title" color="primary">
                    Coordonnées
                  </h3>
                </IonItem>

                <IonItem>
                  <IonInput
                    label="Téléphone "
                    disabled={isViewMode}
                    labelPlacement="floating"
                    value={
                      isViewMode && !formValues.phone ? '-' : formValues.phone
                    }
                    onIonChange={handleChange('phone')}
                    type="tel"
                    autocomplete="tel"
                  />
                </IonItem>
                {formErrors.phone && (
                  <IonText color="danger">{formErrors.phone}</IonText>
                )}
                <IonItem>
                  <IonInput
                    label="Adresse email "
                    disabled={isViewMode}
                    labelPlacement="floating"
                    value={
                      isViewMode && !formValues.email ? '-' : formValues.email
                    }
                    onIonChange={handleChange('email')}
                    type="text"
                  />
                </IonItem>
                {formErrors.email && (
                  <IonText color="danger">{formErrors.email}</IonText>
                )}
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonicSearchAddressInput
                    autocompletionRequest={{
                      types: [],
                      componentRestrictions: { country: ['fr'] },
                    }}
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    label="Adresse usager"
                    initialAddress={{
                      addressLine: detailUsager?.attributes?.addressLine,
                      addressCity: detailUsager?.attributes?.addressCity,
                      addressZipcode: detailUsager?.attributes?.addressZipcode,
                      addressCountry: detailUsager?.attributes?.addressCountry,
                    }}
                    name="address"
                    onSelect={(selectedAddress: any) =>
                      handleChangeAddress(selectedAddress)
                    }
                  />
                  {formErrors.addressLine && (
                    <IonText color="danger">{formErrors.addressLine}</IonText>
                  )}
                </IonItem>
              </IonCol>
            </IonRow>
          </div>
          <div className={!isViewMode ? 'other' : 'other view-mode'}>
            <IonRow>
              <IonCol>
                <IonItem className="ion-align-items-center">
                  <IonImg
                    className="profiles--image"
                    src="/assets/images/clipboard.svg"
                    alt="profil"
                  />
                  <h3 className="profile-title" color="primary">
                    Autre
                  </h3>
                </IonItem>

                <IonText className="label-select">
                  Situation administrative
                </IonText>

                <IonItem>
                  <Selection
                    name="positionAdministrative"
                    onChange={handleChange('positionAdministrative')}
                    mode="md"
                    valueKey="id"
                    fill="outline"
                    value={formValues.positionAdministrative}
                    aria-label="Sélectionner la situation administrative"
                    autocomplete
                    placeholder={
                      isViewMode && !formValues.positionAdministrative
                        ? '-'
                        : 'Sélectionner la situation administrative'
                    }
                    disabled={isViewMode}
                    items={adminData?.administrativePositions?.data.map(
                      (i) => ({ id: i.id, name: i.attributes?.name }),
                    )}
                  />
                </IonItem>
                {formErrors.positionAdministrative && (
                  <IonText color="danger">
                    {formErrors.positionAdministrative}
                  </IonText>
                )}
                {!isViewMode && (
                  <HistoryButton
                    onClick={(e) =>
                      viewHistory(
                        'positionAdministrative',
                        'Situation administrative',
                        adminData?.administrativePositions?.data?.map((i) => ({
                          id: i.id || '',
                          name: i.attributes?.name || '',
                        })),
                      )
                    }
                  />
                )}
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonText className="label-select">Type de ressources</IonText>
                {remunerationTypes && (
                  <Selection
                    name="resources"
                    onChange={handleChange('resources')}
                    mode="md"
                    fill="outline"
                    value={formValues.resources}
                    aria-label="Situation familiale "
                    placeholder={
                      isViewMode && !formValues.resources ? '-' : 'Sélectionner'
                    }
                    disabled={isViewMode}
                    items={remunerationTypes.remunerationTypes?.data?.map(
                      (i) => ({
                        id: i.id,
                        name: i.attributes?.name,
                      }),
                    )}
                  />
                )}
                {!isViewMode && (
                  <HistoryButton
                    onClick={(e) =>
                      viewHistory(
                        'resources',
                        'Type de ressourcess',
                        remunerationTypes?.remunerationTypes?.data?.map(
                          (i) => ({
                            id: i.id || '',
                            name: i.attributes?.name || '',
                          }),
                        ),
                      )
                    }
                  />
                )}
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonInput
                    label="Montant des ressources"
                    disabled={isViewMode}
                    value={
                      isViewMode && !formValues.resourcesPrice
                        ? '-'
                        : formValues.resourcesPrice
                    }
                    onIonChange={handleChange('resourcesPrice')}
                    labelPlacement="floating"
                    type={
                      isViewMode && !formValues.resourcesPrice
                        ? 'text'
                        : 'number'
                    }
                    inputMode="numeric"
                  />
                </IonItem>
                {formErrors.resourcesPrice && (
                  <IonText color="danger">{formErrors.resourcesPrice}</IonText>
                )}
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonText className="label-select">
                  Situation professionnelle
                </IonText>
                {professionalSituations && (
                  <Selection
                    name="professionalSituation"
                    onChange={handleChange('professionalSituation')}
                    mode="md"
                    fill="outline"
                    value={formValues.professionalSituation}
                    aria-label="Situation familiale "
                    placeholder={
                      isViewMode && !formValues.professionalSituation
                        ? '-'
                        : 'Sélectionner'
                    }
                    disabled={isViewMode}
                    items={professionalSituations.professionalSituations?.data?.map(
                      (i) => ({
                        id: i.id,
                        name: i.attributes?.name,
                      }),
                    )}
                  />
                )}
                {!isViewMode && (
                  <HistoryButton
                    onClick={(e) =>
                      viewHistory(
                        'professionalSituation',
                        'Situation professionelle',
                        professionalSituations?.professionalSituations?.data?.map(
                          (i) => ({
                            id: i.id || '',
                            name: i.attributes?.name || '',
                          }),
                        ),
                      )
                    }
                  />
                )}
              </IonCol>
            </IonRow>
          </div>
          <IonRow className="fixed-button ion-no-padding">
            <IonCol className="ion-no-padding">
              <IonButton
                expand="block"
                color="primary"
                size="large"
                className="ion-rounded"
                onClick={clickUpdate}
              >
                {!isViewMode ? 'Sauvegarder' : 'Modifier'}
              </IonButton>
            </IonCol>
          </IonRow>
        </>
      )}

      <ConfirmModal
        isOpen={modalSuccess}
        title="Confirmer les modifications de la fiche usager "
        text="Pour information toutes les prochaines fiches actions de l’usager seront liées à cette nouvelle version."
        okText="Confirmer"
        cancelText="Annuler"
        onCancel={() => showModalSuccess(false)}
        onConfirm={() => {
          handleUpdate()
        }}
      />
      {isCreate && (
        <>
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                color="primary"
                size="large"
                onClick={(e) => handleSubmit(e, 'usagers_actions_fiche')}
              >
                Créer l’usager + Fiche action{' '}
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                size="large"
                fill="outline"
                onClick={(e) => handleSubmit(e, 'usagers_actions_complete')}
              >
                Créer et compléter la fiche usager{' '}
              </IonButton>
            </IonCol>
          </IonRow>
        </>
      )}
      <HistoryModal
        label={currentHistoryData?.label}
        field={currentHistoryData?.field}
        sources={currentHistoryData?.data}
        isOpen={historyModalOpened}
        usager={detailUsager}
        onCancel={(isMainFieldUpdated) => {
          openModalHistory(false)
          if (isMainFieldUpdated && onReload) {
            onReload()
          }
        }}
      />
    </UsagersWrapper>
  )
}
const UsagersWrapper = styled('form')`
  .fixed-button {
    position: sticky;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--ion-color-main-background);
    z-index: 1000;
    width: 100%;
    background-color: #f7f7f7;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
  }
`

export default Usagers
