import { graphql } from 'src/models/gql';

export const CREATE_ACTION_CATEGORY_MUTATION = graphql(/* GraphQL */ `
  mutation createActionCategory($data: ActionCategoryInput!) {
    createActionCategory(data: $data) {
      data {
        id
      }
    }
  }
`);

export const UPDATE_ACTION_CATEGORY_MUTATION = graphql(/* GraphQL */ `
  mutation updateActionCategory($id: ID!, $data: ActionCategoryInput!) {
    updateActionCategory(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`);

export const LIST_ACTION_CATEGORYS = graphql(/* GraphQL */ `
  query listActionCategories(
    $filters: ActionCategoryFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    actionCategories(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          name
          picto {
            data {
              attributes {
                url
                previewUrl
              }
            }
          }
          actionTypes {
            data {
              id
              attributes {
                name
                quantityManageable
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`);

export const FETCH_ONE_ACTION_CATEGORY = graphql(/* GraphQL */ `
  query fetchActionCategoryById($id: ID!) {
    actionCategory(id: $id) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`);
