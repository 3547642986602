/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */

import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonRow,
  IonText,
} from '@ionic/react'
import { arrowBack, createOutline } from 'ionicons/icons'
import { FC, KeyboardEvent, MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  StepTwoAction,
  resetActionTypeSelected,
  setStepTwo,
} from 'src/stores/slices/ficherActionsSlices'

import { Item } from 'src/stores/slices/provider/usagers/usagers.service'
import store, { RootState } from 'src/stores/store'
import { fetchSelectCategoriesActions } from 'src/stores/thunks/fiche-actions-thunks'
import { getOrgId, isAdmin } from 'src/utils/auth'
import ConfirmModalQuantity from '../Modals/ConfirmModal/ConfirmModalQuantity'
import './ActionType.css'

export interface IActionTypeItemProps {
  categoriesName?: string
  categorieImage?: string
  items?: Item[]
  isSearch?: boolean
  getEditData: (data: any) => void
}

const ActionTypeItem: FC<IActionTypeItemProps> = ({
  categoriesName,
  categorieImage,
  items,
  isSearch,
  getEditData,
}) => {
  const { selectActionType } = useSelector(
    (state: RootState) => state.ficheActions,
  )
  const [idClickFocus, setIdFocus] = useState<string>('')
  const [modalSuccess, showModalSuccess] = useState(false)
  const [name, setName] = useState('')
  const [idAction, setIdAction] = useState('')

  const handleBack = (): void => {
    store.dispatch(resetActionTypeSelected())
  }

  const select = (
    id: string,
    nameAction: string,
    actionId: string,
    event:
      | React.MouseEvent<HTMLIonTextElement>
      | React.KeyboardEvent<HTMLIonTextElement>,
    quantityManageable?: boolean,
  ): void => {
    setIdFocus(nameAction)
    setTimeout(() => {
      setIdFocus('')
    }, 250)
    if (!selectActionType && !isSearch) {
      store.dispatch(
        fetchSelectCategoriesActions({
          filters: {
            and: [
              {
                organization: { id: { eq: getOrgId() } },
              },

              {
                category: {
                  id: { eq: id },
                },
              },
            ],
          },
          pagination: { page: 1, pageSize: 52 },
          sort: ['updatedAt:DESC'],
        }),
      )
    } else {
      setName(nameAction)
      setIdAction(actionId)
      if (quantityManageable) {
        showModalSuccess(true)
      } else {
        const actionsTypes = {
          id: actionId,
          name: nameAction,
          quantity: 0,
        } as StepTwoAction

        const myButton = document.getElementById(
          'clear-step',
        ) as HTMLButtonElement

        setTimeout(() => {
          if (myButton !== null) {
            myButton.click()
          }

          store.dispatch(setStepTwo(actionsTypes))
          store.dispatch(resetActionTypeSelected())
        }, 500)
      }
    }
    event.preventDefault()
  }

  return (
    <IonGrid className="ion-no-padding">
      {selectActionType && (
        <>
          <IonButton
            className="inner-back-button"
            fill="clear"
            color="medium"
            onClick={handleBack}
          >
            <IonIcon slot="start" icon={arrowBack} /> Retour
          </IonButton>
          <div className="wrap-catagory ion-margin-bottom">
            {/* <div
              className={!modalSuccess ? 'card-icon' : 'card-icon green'}
              dangerouslySetInnerHTML={{ __html: categorieImage }}
      /> */}

            {categorieImage ? (
              <IonImg
                src={categorieImage}
                alt="admin"
                className={!modalSuccess ? 'filter-orange' : 'filter-green'}
                style={{ width: 32, height: 32 }}
              />
            ) : (
              <IonImg
                src="/assets/images/admin.svg"
                alt="default"
                className={modalSuccess ? 'filter-orange' : ''}
                style={{ width: 32, height: 32 }}
              />
            )}
            <p className="category-title">{categoriesName} </p>
          </div>
        </>
      )}
      <IonRow>
        <IonCol size="12">
          {items?.map((actionType, index) => (
            <div
              className={`list-category ${
                idClickFocus === actionType.attributes.name ? 'selected' : ''
              }`}
              key={actionType.attributes.name}
              role="button"
              tabIndex={index}
            >
              <IonText
                className="title"
                onClick={(event: MouseEvent<HTMLIonTextElement>) =>
                  select(
                    actionType.attributes.category.data.id,
                    actionType.attributes.name,
                    actionType.id,
                    event,
                    actionType.attributes.quantityManageable,
                  )
                }
                onKeyDown={(event: KeyboardEvent<HTMLIonTextElement>) => {
                  if (event.keyCode === 13) {
                    select(
                      actionType.attributes.category.data.id,
                      actionType.attributes.name,
                      actionType.id,
                      event,
                      actionType.attributes.quantityManageable,
                    )
                  }
                }}
              >
                {actionType.attributes.name}
              </IonText>
              {!selectActionType && (
                <IonText className="desc">{categoriesName}</IonText>
              )}{' '}
              {isAdmin() && (
                <div className="icon-edit">
                  <IonIcon
                    icon={createOutline}
                    onClick={() => {
                      const data = {
                        categorieId: actionType?.attributes?.category?.data?.id,
                        actionId: actionType?.id,
                        name: actionType?.attributes?.name,
                        quantity: actionType?.attributes?.quantityManageable,
                      } as any
                      getEditData(data)
                    }}
                  />
                </div>
              )}
            </div>
          ))}
        </IonCol>
      </IonRow>

      <ConfirmModalQuantity
        isOpen={modalSuccess}
        title={`Merci de définir la quantité liée à l’action ${name}`}
        name={name}
        idAction={idAction}
        okText="Confirmer"
        cancelText="Annuler"
        onCancel={() => showModalSuccess(false)}
      />
    </IonGrid>
  )
}

export default ActionTypeItem
