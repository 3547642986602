export const nationality = [
  '*Française',
  '*Hors UE',
  '*Sans nationalité',
  '*Union Européenne',
  'Afghanistan',
  'Albanie',
  'Algérie',
  'Allemagne',
  'Angola',
  'Apatride',
  'Arménie',
  'Azerbaidjan',
  'Bulgarie',
  'Burundi',
  'Cameroun',
  'Centre Afrique',
  'Comores',
  'Congo',
  "Côte d'Ivoire",
  'Erythrée',
  'Espagne',
  'Ethiopie',
  'Gabon',
  'Géorgie',
  'Guinée',
  'Guinée Bissau',
  'Guinée Equatoriale',
  'Haîti',
  'Irak',
  'Italie',
  'Kosovo',
  'Lybie',
  'Mali',
  'Maroc',
  'Mauritanie',
  'Mongolie',
  'Nigeria',
  'NR',
  'Portugal',
  'Roumanie',
  'Russie',
  'Rwanda',
  'Sénégal',
  'Serbie',
  'Syrie',
  'Togo',
  'Tunisie',
  'Turquie',
  'Ukraine',
  'Yemen',
];
