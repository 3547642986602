import { InputChangeEventDetail, IonCol, IonRow, IonText } from '@ionic/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Selection from 'src/components/Selection/Selection'
import { setStepOne } from 'src/stores/slices/ficherActionsSlices'
import { RootState } from 'src/stores/store'

export interface StepProps {
  submittedStep1?: boolean
}
const StepOne: React.FC<StepProps> = ({ submittedStep1 }): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const STEP_ONE = useSelector(
    (state: RootState) => state.ficheActions.step_one,
  )
  const dispatch = useDispatch()

  const { serviceList, orientationList } = useSelector(
    (state: RootState) => state.ficheActions,
  )

  const handleServiceChange = (
    event: CustomEvent<InputChangeEventDetail>,
  ): void => {
    const { value } = event.detail
    dispatch(setStepOne({ ...STEP_ONE, service: value?.toString() }))
  }

  const handleOrientationChange = (
    event: CustomEvent<InputChangeEventDetail>,
  ): void => {
    const { value } = event.detail
    dispatch(setStepOne({ ...STEP_ONE, orientation: value?.toString() }))
  }

  /* const handleDescriptionChange = (
    ev: CustomEvent<InputChangeEventDetail>,
  ): void => {
    const { value } = ev.target as HTMLInputElement
    dispatch(setStepOne({ ...STEP_ONE, description: value }))
  } */

  return (
    <form>
      {serviceList?.services.data && serviceList?.services.data.length > 1 && (
        <IonRow class="tabs-row">
          <IonCol>
            <IonText className="label-select">Service</IonText>

            <Selection
              name="service"
              onChange={handleServiceChange}
              mode="md"
              fill="outline"
              value={STEP_ONE.service}
              aria-label="service"
              autocomplete
              placeholder="Sélectionner un service"
              items={
                serviceList?.services.data &&
                (serviceList.services.data as any[])
                  .slice()
                  .sort((a: any, b: any) =>
                    a.attributes.name.localeCompare(b.attributes.name),
                  )
                  .map((i: any) => ({
                    id: i.id,
                    name: i.attributes?.name,
                  }))
              }
            />
            {submittedStep1 && !STEP_ONE.service && (
              <IonText color="danger">Le champ est obligatoire</IonText>
            )}
          </IonCol>
        </IonRow>
      )}
      <IonRow class="tabs-row">
        <IonCol>
          <IonText className="label-select">Orientation effectuée par</IonText>

          <Selection
            name="orientation"
            onChange={handleOrientationChange}
            mode="md"
            fill="outline"
            value={STEP_ONE.orientation}
            aria-label="orientation"
            autocomplete
            placeholder="Sélectionner un signalement"
            items={
              orientationList?.orientationSources?.data &&
              (orientationList.orientationSources.data as any[])
                .slice()
                .sort((a: any, b: any) =>
                  a.attributes.name.localeCompare(b.attributes.name),
                )
                .map((i: any) => ({
                  id: i?.id,
                  name: i.attributes?.name,
                }))
            }
          />
          {submittedStep1 && !STEP_ONE.orientation && (
            <IonText color="danger">Le champ est obligatoire</IonText>
          )}
        </IonCol>
      </IonRow>
      {/*
      <IonRow class="tabs-row">
        <IonCol>
          <IonText className="label-select">
            Description du signalement{' '}
          </IonText>
          <IonTextarea
            autoGrow
            className="textarea-signal"
            placeholder="Votre description..."
            fill="solid"
            name="message"
            value={STEP_ONE.description}
            onIonInput={(e) => handleDescriptionChange(e)}
            required
          />
          {submittedStep1 && !STEP_ONE.description && (
            <IonText color="danger">Le champ est obligatoire</IonText>
          )}
        </IonCol>
          </IonRow> */}
    </form>
  )
}

export default StepOne
