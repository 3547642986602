import { graphql } from 'src/models/gql';

export const CREATE_TRANSMISSION_NOTE = graphql(/* GraphQL */ `
  mutation createTransmissionNote($data: TransmissionNoteInput!) {
    createTransmissionNote(data: $data) {
      data {
        id
      }
    }
  }
`);

export const FETCH_ONE_TRANSMISSION = graphql(/* GraphQL */ `
  query transmissionNote($id: ID!) {
    transmissionNote(id: $id) {
      data {
        id
        attributes {
          description
          isPriority
          isNew
          author {
            data {
              id
              attributes {
                firstname
                lastname
              }
            }
          }

          createdAt
        }
      }
    }
  }
`);

export const UPDATE_TRANSMISSION_NOTE = graphql(/* GraphQL */ `
  mutation updateTransmissionNote($id: ID!, $data: TransmissionNoteInput!) {
    updateTransmissionNote(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`);

export const LIST_TRANSMISSION_NOTE = graphql(/* GraphQL */ `
  query transmissionNotes(
    $filters: TransmissionNoteFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    transmissionNotes(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          description
          isPriority
          isNew
          author {
            data {
              id
              attributes {
                firstname
                lastname
              }
            }
          }

          createdAt
        }
      }
    }
  }
`);

export const DELETE_TRANSMISSION_NOTE = graphql(/* GraphQL */ `
  mutation deleteTransmissionNote($id: ID!) {
    deleteTransmissionNote(id: $id) {
      data {
        id
      }
    }
  }
`);
