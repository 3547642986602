import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: 'paxtel-staging.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = (): Promise<void | string> => {
  return getToken(messaging, {
    vapidKey: `${process.env.REACT_APP_FIREBASE_VAPID_KEY}`,
  });
};
export const requestPermission =  (): Promise<void | string> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    if ('Notification' in window) {
      const permission = await Notification.requestPermission();
      if (permission === 'denied') {
        reject(new Error('Malheureusement vous avez déjà bloqué la push notification sur ce site, veuillez reinitialiser votre configuration'));
      }
      if (permission === 'granted') {
        console.log('vous avez autorisé')
        resolve();
      }
      if (permission === 'default') {
        console.log('default here')
        reject(new Error('Impossible de lancer'))
      }
    }
  });
};
// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = (): Promise<unknown> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
