import {
  InputChangeEventDetail,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonModal,
  IonRow,
  IonText,
  IonToolbar,
  useIonLoading,
} from '@ionic/react'
import { useEffect, useRef, useState } from 'react'
import Selection from 'src/components/Selection/Selection'
import { StorageUtils, getOrgId, isAdmin } from 'src/utils'

interface ModalProps {
  isOpen?: boolean
  categorieId: string
  dataEdit?: any
  categoriesListData: any[]
  closeModal: (id?: string) => any
}

function ActionModal({
  isOpen,
  closeModal,
  categorieId,
  categoriesListData,
  dataEdit,
}: ModalProps) {
  const modal = useRef<HTMLIonModalElement>(null)
  const page = useRef(undefined)
  const [showModal, setShowModal] = useState(isOpen)
  const [nom, setNom] = useState('')
  const [categorie, setCategorie] = useState('')
  const [quantity, setQuantity] = useState(false)
  const [loading, setLoading] = useState(false)
  const [categorieList, setCategorieList] = useState<any[]>(
    categoriesListData || [],
  )
  const [isSubmitted, setSubmitted] = useState(false)
  const [presentLoading, dismissLoading] = useIonLoading()
  useEffect(() => {
    setShowModal(isOpen)
    if (isOpen) {
      if (categorieId !== '') {
        setCategorie(categorieId)
      }
      if (dataEdit?.actionId) {
        setCategorie(dataEdit?.categorieId)
        setNom(dataEdit?.name)
        setQuantity(dataEdit?.quantity)
      }
    }
  }, [isOpen, setShowModal, categorieId, dataEdit])

  useEffect(() => {
    if (loading) {
      presentLoading()
    } else {
      dismissLoading()
    }
    return () => {
      dismissLoading()
    }
  }, [loading, presentLoading, dismissLoading])

  const handleSubmit = async () => {
    setSubmitted(true)
    if (!nom || !categorie) {
      return
    }
    setLoading(true)
    const token = StorageUtils.get('authToken')
    const payload = {
      data: {
        organization: getOrgId(),
        category: categorie,
        name: nom,
        quantityManageable: quantity,
        isFromApp: true,
      },
    }

    const response = await fetch(
      `${process.env.REACT_APP_AUTH_URI}/api/action-types`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      },
    )

    const responseData = await response.json()

    if (responseData) {
      setLoading(false)
      handleCancel(categorie)
    } else {
      setLoading(false)
    }
  }

  const handleSubmitEdit = async () => {
    setSubmitted(true)
    if (!nom || !categorie) {
      return
    }
    setLoading(true)
    const token = StorageUtils.get('authToken')
    const payload = {
      data: {
        organization: getOrgId(),
        category: categorie,
        name: nom,
        quantityManageable: quantity,
        isFromApp: true,
      },
    }

    const response = await fetch(
      `${process.env.REACT_APP_AUTH_URI}/api/action-types/${dataEdit?.actionId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      },
    )

    const responseData = await response.json()

    if (responseData) {
      setLoading(false)
      handleCancel(categorie)
    } else {
      setLoading(false)
    }
  }

  const handleCancel = (id?: string) => {
    setSubmitted(false)
    setShowModal(false)
    setNom('')
    setCategorie('')
    setQuantity(false)
    closeModal(id)
  }

  const handleCategorieChange = (
    event: CustomEvent<InputChangeEventDetail>,
  ): void => {
    const { value } = event.detail

    setCategorie(value?.toString() as any)
  }

  const handleCheckboxChange = (event: CustomEvent): void => {
    const { checked } = event.detail
    setQuantity(checked)
  }

  return (
    <IonModal ref={modal} isOpen={showModal}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={() => handleCancel()} strong>
              Annuler
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonList lines="none">
                <IonItem>
                  <IonCol>
                    <IonInput
                      className="ion-valid"
                      label="Nom action"
                      labelPlacement="stacked"
                      value={nom}
                      type="text"
                      onIonInput={(e) => setNom(e.target.value as any)}
                    />{' '}
                    {isSubmitted && !nom && (
                      <IonText color="danger">Ce champ est requis</IonText>
                    )}
                  </IonCol>
                </IonItem>{' '}
                <IonItem>
                  <IonCol>
                    <IonText className="label-select">Catégorie</IonText>
                    <Selection
                      name="service"
                      onChange={handleCategorieChange}
                      mode="md"
                      fill="outline"
                      value={categorie}
                      aria-label="service"
                      autocomplete
                      placeholder="Sélectionner une categorie"
                      items={
                        categorieList.length > 0 &&
                        categorieList.map((i: any) => ({
                          id: i?.id,
                          name: i?.attributes?.name,
                        }))
                      }
                    />
                    {isSubmitted && !categorie && (
                      <IonText color="danger">Ce champ est requis</IonText>
                    )}
                  </IonCol>
                </IonItem>
                <IonItem>
                  <IonCol>
                    <IonItem>
                      <IonCheckbox
                        justify="start"
                        checked={quantity === true}
                        onIonChange={handleCheckboxChange}
                        labelPlacement="end"
                      >
                        Gestion des quantités ?
                      </IonCheckbox>
                    </IonItem>
                    {false && <IonText color="danger">Champ requis</IonText>}
                  </IonCol>
                </IonItem>
                <IonButton
                  expand="block"
                  color="primary"
                  size="large"
                  onClick={() => {
                    if (dataEdit?.actionId) {
                      handleSubmitEdit()
                    } else {
                      handleSubmit()
                    }
                  }}
                >
                  {isAdmin() && dataEdit?.actionId
                    ? 'Modifier une action'
                    : 'Créer une action'}
                </IonButton>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  )
}

export default ActionModal
