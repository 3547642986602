/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import { useLazyQuery } from '@apollo/client'
import {
  InputChangeEventDetail,
  IonButton,
  IonCheckbox,
  IonCol,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  useIonViewDidEnter,
} from '@ionic/react'
import { add } from 'ionicons/icons'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppDatepicker, ModalLayout } from 'src/components'
import IonicSearchAddressInput from 'src/components/IonicSearchAddressInput/IonicSearchAddressInput'
import Selection from 'src/components/Selection/Selection'
import { LIST_ACTION_TYPES } from 'src/graphql/actions-types.graphql'
import { USERS_PERMISSIONS_LIST } from 'src/graphql/auth.graphql'
import { GET_FAMILY } from 'src/graphql/utilities.graphql'
import {
  resetFilters,
  updateFilter,
} from 'src/stores/slices/ficherActionsSlices'
import store, { RootState } from 'src/stores/store'
import {
  fetcCategoriesActionsData,
  fetchServiceData,
} from 'src/stores/thunks/fiche-actions-thunks'
import { getOrgId, getUserData } from 'src/utils/auth'
import { formatDateInsertion } from 'src/utils/formatting'
import './Actions.css'

const FilterAction: React.FC = () => {
  const history = useHistory()
  const user = getUserData()
  const [loadFamilies, { data: familiesData }] = useLazyQuery(GET_FAMILY)
  const [loadDataAction, { data: actionData }] = useLazyQuery(LIST_ACTION_TYPES)
  const [authorLists, { data: authorListData }] = useLazyQuery(
    USERS_PERMISSIONS_LIST,
  )
  const [localisationTemp, setLocatisationTemp] = useState({} as any)
  useIonViewDidEnter(async () => {
    initForm()
    await loadFamilies({
      variables: { pagination: { limit: 500, start: 0 }, sort: ['name:ASC'] },
    })

    await loadDataAction({
      variables: {
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },
          ],
        },
        pagination: { limit: 500, start: 0 },
        sort: ['name:DESC'],
      },
    })
    await authorLists({
      variables: {
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },
          ],
        },
        pagination: { limit: 500, start: 0 },
      },
    })
  })

  const initForm = (): void => {
    store.dispatch(
      fetchServiceData({
        filters: {
          and: [
            /* {
              organization: { id: { eq: getOrgId() } },
            },

            {
              users: { id: { in: getUserId() } },
            }, */
          ],
        },
        pagination: { page: 1, pageSize: 200 },

        sort: ['updatedAt:DESC'],
      }),
    )

    store.dispatch(
      fetcCategoriesActionsData({
        filters: {
          and: [
            /* {
              organization: { id: { eq: getOrgId() } },
            },

            {
              services: {
                id: {
                  in: user?.services?.data.map((res: any) => {
                    return res?.id
                  }),
                },
              },
            }, */
          ],
        },
        pagination: { page: 1, pageSize: 200 },
        sort: ['updatedAt:DESC'],
      }),
    )
  }

  const { serviceList, actionsList, filters } = useSelector(
    (state: RootState) => state.ficheActions,
  )

  const handleBack = (): void => {
    history.replace('/main/actions')
  }
  const handleCancel = (): void => {
    store.dispatch(resetFilters())
    history.replace('/main/actions')
  }

  const handleChangeAddress = (address: IAddress): void => {
    console.log(address)
    setLocatisationTemp({
      addressCity: address.addressCity,
      addressCountry: address.addressCountry,
      addressLat: address.addressLat,
      addressLine: address.addressLine,
      addressLng: address.addressLng,
    })
    store.dispatch(
      updateFilter({
        field: 'usagerLocalisation',
        value: address?.addressZipcode || ' ',
      }),
    )
  }

  const handleChange = (key: keyof any) => (
    event: CustomEvent<InputChangeEventDetail | HTMLIonSelectElement>,
  ) => {
    const { value } = event.detail

    if (key === 'createdAt') {
      if (value) {
        const daty = formatDateInsertion(value?.toString())
        store.dispatch(
          updateFilter({
            field: 'created_date',
            value: daty?.toString() || '',
          }),
        )
      }
    }
    if (key === 'usagerFirstName') {
      store.dispatch(
        updateFilter({
          field: 'usagerFirstName',
          value: value || '',
        }),
      )
    }

    if (key === 'usagerLastName') {
      store.dispatch(
        updateFilter({
          field: 'usagerLastName',
          value: value || '',
        }),
      )
    }
  }

  const handleCheckboxChange = (): void => {
    if (filters.isUnknown) {
      store.dispatch(
        updateFilter({
          field: 'isUnknown',
          value: '',
        }),
      )
    } else {
      store.dispatch(
        updateFilter({
          field: 'isUnknown',
          value: 'real',
        }),
      )
    }
  }

  const changeAuthor = (event: CustomEvent<InputChangeEventDetail>): void => {
    const { value } = event.detail
    store.dispatch(
      updateFilter({ field: 'createdBy', value: value?.toString() || '' }),
    )
  }

  return (
    <IonPage>
      <ModalLayout title="Filtres" onBack={handleBack}>
        <IonGrid>
          <div className="dates">
            <IonRow>
              <IonCol>
                <IonItem className="ion-align-items-center">
                  <IonImg
                    className="profiles--image"
                    src="/assets/images/calendar.svg"
                    alt="Calendar"
                  />
                  <h3 className="profile-title" color="primary">
                    Dates
                  </h3>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <AppDatepicker
                  preferWheel
                  name="birthDate"
                  label="Dates d'action"
                  presentation="date"
                  displayFormat="dd/MM/yyyy"
                  initialValue={filters.created_date}
                  onChange={handleChange('createdAt')}
                />
              </IonCol>
            </IonRow>
          </div>

          <div className="usagers">
            <IonRow>
              <IonCol>
                <IonItem className="ion-align-items-center">
                  <IonImg
                    className="profiles--image"
                    src="/assets/images/usagers-icon.svg"
                    alt="Usager"
                  />
                  <h3 className="profile-title" color="primary">
                    Usagers
                  </h3>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonInput
                  label="Nom"
                  labelPlacement="floating"
                  type="text"
                  value={filters.usagerLastName}
                  onIonChange={handleChange('usagerLastName')}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonInput
                  label="Prénom"
                  labelPlacement="floating"
                  value={filters.usagerFirstName}
                  onIonChange={handleChange('usagerFirstName')}
                  type="text"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonicSearchAddressInput
                    autocompletionRequest={{
                      types: [],
                      componentRestrictions: { country: ['fr'] },
                    }}
                    initialAddress={{
                      addressZipcode: filters?.usagerLocalisation,
                      addressLine: localisationTemp?.addressLine,
                      addressCity: localisationTemp?.addressCity,
                      addressCountry: localisationTemp?.addressCountry,
                    }}
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    label="Localisation"
                    name="address"
                    onSelect={(selectedAddress: any) =>
                      handleChangeAddress(selectedAddress)
                    }
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText className="label-select">Situation familiale </IonText>
                <IonSelect
                  aria-label="family-status"
                  multiple
                  className="multiple-select"
                  value={filters.usagerSituationFamiliale}
                  onIonChange={(item: any) => {
                    store.dispatch(
                      updateFilter({
                        field: 'usagerSituationFamiliale',
                        value: item.detail.value,
                      }),
                    )
                  }}
                >
                  {familiesData &&
                    familiesData.familyStatuses?.data &&
                    (familiesData.familyStatuses.data as any[])
                      .slice()
                      .sort((a: any, b: any) =>
                        a.attributes.name.localeCompare(b.attributes.name),
                      )
                      .map((familie: any) => (
                        <IonSelectOption value={familie?.id} key={familie?.id}>
                          {familie?.attributes?.name}
                        </IonSelectOption>
                      ))}
                </IonSelect>
              </IonCol>
            </IonRow>
          </div>

          <div className="action">
            <IonRow>
              <IonCol>
                <IonItem className="ion-align-items-center">
                  <IonImg
                    className="profiles--image"
                    src="/assets/images/flag.svg"
                    alt="Actions"
                  />
                  <h3 className="profile-title" color="primary">
                    Action
                  </h3>
                </IonItem>
                <IonItem className="item-check">
                  <IonLabel className="label-check">
                    Actions avec des inconnus
                  </IonLabel>
                  <IonCheckbox
                    className="priority-check"
                    slot="start"
                    checked={filters.isUnknown !== ''}
                    onIonChange={handleCheckboxChange}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText className="label-select">Service </IonText>
                <IonSelect
                  aria-label="family-status"
                  placeholder="Sélectionner un service"
                  multiple
                  className="multiple-select"
                  value={filters.services}
                  onIonChange={(item: any) => {
                    store.dispatch(
                      updateFilter({
                        field: 'services',
                        value: item.detail.value,
                      }),
                    )
                  }}
                >
                  {serviceList &&
                    serviceList.services.data &&
                    (serviceList.services.data as any[])
                      .slice()
                      .sort((a: any, b: any) =>
                        a.attributes.name.localeCompare(b.attributes.name),
                      )
                      .map((service: any) => (
                        <IonSelectOption value={service?.id} key={service?.id}>
                          {service?.attributes?.name}
                        </IonSelectOption>
                      ))}
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonText className="label-select"> Catégorie action </IonText>
                <IonSelect
                  aria-label="categorie-action"
                  placeholder="Sélectionner une catégorie action"
                  multiple
                  className="multiple-select"
                  value={filters.categories}
                  onIonChange={(item: any) => {
                    store.dispatch(
                      updateFilter({
                        field: 'categories',
                        value: item.detail.value,
                      }),
                    )
                  }}
                >
                  {actionsList?.actionCategories.data &&
                    (actionsList.actionCategories.data as any[])
                      .slice()
                      .sort((a: any, b: any) =>
                        a.attributes.name.localeCompare(b.attributes.name),
                      )
                      .map((actionsCateg: any) => (
                        <IonSelectOption
                          value={actionsCateg?.id}
                          key={actionsCateg?.id}
                        >
                          {actionsCateg?.attributes?.name}
                        </IonSelectOption>
                      ))}
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonText className="label-select"> Action </IonText>
                <IonSelect
                  aria-label="actionType-status"
                  placeholder="Sélectionner une  action"
                  multiple
                  className="multiple-select"
                  value={filters.actions}
                  onIonChange={(item: any) => {
                    store.dispatch(
                      updateFilter({
                        field: 'actions',
                        value: item.detail.value,
                      }),
                    )
                  }}
                >
                  {actionData &&
                    actionData.actionTypes?.data &&
                    (actionData.actionTypes.data as any[])
                      .slice()
                      .sort((a: any, b: any) =>
                        a.attributes.name.localeCompare(b.attributes.name),
                      )
                      .map((actionType: any) => (
                        <IonSelectOption
                          value={actionType?.id}
                          key={actionType?.id}
                        >
                          {actionType?.attributes?.name}
                        </IonSelectOption>
                      ))}
                </IonSelect>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText className="label-select">Créer par </IonText>
                {authorListData && (
                  <Selection
                    name="familyStatus"
                    mode="md"
                    fill="outline"
                    onChange={changeAuthor}
                    value={filters.createdBy}
                    aria-label="Créer par "
                    items={authorListData?.usersPermissionsUsers?.data
                      ?.slice()
                      .sort((a: any, b: any) =>
                        `${a.attributes?.firstname} ${a.attributes?.lastname}`.localeCompare(
                          `${b.attributes?.firstname} ${b.attributes?.lastname}`,
                        ),
                      )
                      .map((i: any) => ({
                        id: i.id,
                        name: `${i.attributes?.firstname} ${i.attributes?.lastname}`,
                      }))}
                  />
                )}
              </IonCol>
            </IonRow>
          </div>

          <IonRow className="fixed-button ion-no-padding">
            <IonCol className="ion-no-padding" size="12" onClick={handleBack}>
              <IonButton color="primary" expand="block" size="large">
                Continuer
              </IonButton>
            </IonCol>
            <IonCol className="ion-no-padding" size="12" onClick={handleCancel}>
              <IonButton fill="clear" color="primary" expand="block">
                Annuler
              </IonButton>
            </IonCol>
          </IonRow>

          <IonFab className="ion-fab">
            <IonFabButton>
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        </IonGrid>
      </ModalLayout>
    </IonPage>
  )
}

export default FilterAction
