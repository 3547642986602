import { LIST_ACTION_TYPES } from 'src/graphql/actions-types.graphql';
import { FETCH_ONE_USER, UPDATE_PROFILS } from 'src/graphql/auth.graphql';
import { LIST_ACTION_CATEGORYS } from 'src/graphql/category-action.graphql';
import { FETCH_ONE_USAGER, LIST_USAGERS } from 'src/graphql/usagers.graphql';
import {
  GET_ORIENTATIONS_LIST,
  GET_SERVICES_LIST,
} from 'src/graphql/utilities.graphql';
import {
  ActionCategoryEntity,
  ActionTypeEntity,
  OrientationSourceEntity,
  ServiceEntity,
  UsagerEntity,
  UsersPermissionsPermissionEntity,
} from 'src/models/gql/graphql';

import ApolloService from 'src/services/apollo.service';

const client = ApolloService.getInstance();

export type IUsagerSingle = {
  usager: {
    data?: UsagerEntity;
  };
};
type FilterType = {
  [key: string]: unknown;
};
interface Filters {
  and: FilterType[];
}
interface Pagination {
  page: number;
  pageSize: number;
}

type Category = {
  id: string;
  attributes: {
    picto: {
      data: {
        attributes: {
          url: string;
          previewUrl: string;
        };
      };
    };
    name: string;
  };
};

export type Item = {
  id: string;
  attributes: {
    name: string;
    quantityManageable: boolean;
    category: {
      data: Category;
    };
  };
};

export const getUsersList = async (
  filters: Filters = {
    and: [],
  },
  pagination: Pagination = { page: 1, pageSize: 50 },
  sort: string[] = []
): Promise<UsagerEntity[]> => {
  const { data } = await client.query<UsagerEntity[]>({
    query: LIST_USAGERS,
    variables: { filters, pagination, sort },
  });

  return data;
};

export const getSetupFicheActions = async (
  filters: Filters = {
    and: [],
  },
  pagination: Pagination = { page: 1, pageSize: 50 },
  sort: string[] = []
): Promise<ActionCategoryEntity[]> => {
  const { data } = await client.query<ActionCategoryEntity[]>({
    query: LIST_ACTION_CATEGORYS,
    variables: { filters, pagination, sort },
  });

  return data;
};

export const getSetupServiceList = async (
  filters: Filters = {
    and: [],
  },
  pagination: Pagination = { page: 1, pageSize: 50 },
  sort: string[] = []
): Promise<ServiceEntity[]> => {
  const { data } = await client.query<ServiceEntity[]>({
    query: GET_SERVICES_LIST,
    variables: { filters, pagination, sort },
  });

  return data;
};

export const getSetupOrientation = async (
  filters: Filters = {
    and: [],
  },
  pagination: Pagination = { page: 1, pageSize: 50 },
  sort: string[] = []
): Promise<OrientationSourceEntity[]> => {
  const { data } = await client.query<OrientationSourceEntity[]>({
    query: GET_ORIENTATIONS_LIST,
    variables: { filters, pagination, sort },
  });

  return data;
};

export const selectCategories = async (
  filters: Filters = {
    and: [],
  },
  pagination: Pagination = { page: 1, pageSize: 50 },
  sort: string[] = []
): Promise<ActionTypeEntity[]> => {
  const { data } = await client.query<ActionTypeEntity[]>({
    query: LIST_ACTION_TYPES,
    variables: { filters, pagination, sort },
  });

  return data;
};

export const groupByCategory = (items: Item[]): Record<string, Item[]> => {
  const grouped: Record<string, Item[]> = {};

  items.forEach((item) => {
    const categoryName = item?.attributes?.category?.data?.attributes?.name;
    if (grouped[categoryName]) {
      grouped[categoryName].push(item);
    } else {
      grouped[categoryName] = [item];
    }
  });

  return grouped;
};

export const getUsagerDefault = async (
  idUsager: string | number
): Promise<IUsagerSingle> => {
  const { data } = await client.query<IUsagerSingle>({
    query: FETCH_ONE_USAGER,
    variables: { id: idUsager },
  });

  return data;
};

export const getServiceUserConnected = async (
  idUser: string | number
): Promise<UsersPermissionsPermissionEntity> => {
  const { data } = await client.query<UsersPermissionsPermissionEntity>({
    query: FETCH_ONE_USER,
    variables: { id: idUser },
  });

  return data;
};

export const setTokenPushNotifications = async (
  idUser: string | number,
  token: string
): Promise<UsersPermissionsPermissionEntity> => {
  const { data } = await client.query<UsersPermissionsPermissionEntity>({
    query: UPDATE_PROFILS,
    variables: { id: idUser, pushToken: token },
  });

  return data;
};
