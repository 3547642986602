/* eslint-disable no-nested-ternary */
import {
  IonBadge,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonText,
} from '@ionic/react'
import { format } from 'date-fns'
import { chevronForwardOutline } from 'ionicons/icons'
import { FC } from 'react'
import { useHistory } from 'react-router'
import { ActionEntity } from 'src/models/gql/graphql'

import './ActionListItem.css'

/* interface ActionEntityWithCount extends ActionEntity {
  count: number
} */
export interface IActionListItemProps {
  action: ActionEntity
  actionId?: string
}

const ActionListItem: FC<IActionListItemProps> = ({
  action,
  actionId,
}: IActionListItemProps) => {
  const history = useHistory()

  return (
    <IonItem
      className="action_list_item"
      onClick={() => history.replace(`/main/actions/${action.id}`)}
    >
      <div className="ion-align-items-center actions--item">
        {/* <svg className="feather small">
          <use href="/assets/icon/feather-sprite.svg#users" />
        </svg> */}
        <div className="actions--image">
          {action?.attributes?.actionType?.data?.attributes?.category?.data
            ?.attributes?.picto?.data?.attributes?.url ? (
            <IonImg
              src={
                action?.attributes?.actionType?.data?.attributes?.category?.data
                  ?.attributes?.picto?.data?.attributes?.url
              }
              className="filter-orange"
              alt="Css"
              style={{ width: 24, height: 24 }}
            />
          ) : (
            <IonImg src="/assets/images/cup.svg" alt="Css" />
          )}
        </div>
        <div className="actions--counter">
          <IonLabel>
            <h3>{action?.attributes?.actionType?.data?.attributes?.name}</h3>
            <IonBadge
              className={
                action?.attributes?.quantity && action?.attributes?.quantity > 0
                  ? 'counter'
                  : 'counter hide'
              }
            >
              {action?.attributes?.quantity && action?.attributes?.quantity > 0
                ? action?.attributes?.quantity
                : null}
            </IonBadge>
          </IonLabel>

          <IonText className="name">
            {action?.attributes?.nbUnknownUsagers &&
            action?.attributes?.nbUnknownUsagers > 0
              ? `${action?.attributes?.nbUnknownUsagers}  inconnu${
                  action?.attributes?.nbUnknownUsagers > 1 ? 's' : ''
                } • `
              : action?.attributes?.usagers &&
                action?.attributes?.usagers?.data?.length > 1
              ? `${action?.attributes?.usagers?.data?.length} usagers • `
              : `${action?.attributes?.usagers?.data[0]?.attributes?.firstname} ${action?.attributes?.usagers?.data[0]?.attributes?.lastname} • `}
            {action?.attributes?.actionDate
              ? format(new Date(action?.attributes?.actionDate), 'dd/MM/yyyy')
              : '-'}
          </IonText>
        </div>
        <IonIcon
          icon={chevronForwardOutline}
          size="large"
          color="danger"
          className="action-arrow"
        />
      </div>
    </IonItem>
  )
}

export default ActionListItem
