/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  InputChangeEventDetail,
  IonButton,
  IonCol,
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonText,
  useIonLoading,
} from '@ionic/react'
import { arrowBack, notificationsCircleOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Cgu, ModalLayout } from 'src/components'
import { ComponentModal } from 'src/components/Modals'
import { ME_QUERY, UPDATE_PROFILS } from 'src/graphql/auth.graphql'
import useToast from 'src/hooks/toast'
import { getUserId, StorageUtils } from 'src/utils/'
import { requestForToken, requestPermission } from 'src/utils/firebase'
import styled from 'styled-components'
import './Profils.css'

type FormValues = {
  email: string
  firstname: string
  lastname: string
  phone: string
  urlAgenda?: string
  urlAgendaTemp?: string
  isAgendaPaxtel?: boolean
  isAgendaPerso?: boolean
}

const initialFormValues: FormValues = {
  email: '',
  firstname: '',
  lastname: '',
  phone: '',
  urlAgenda: '',
  isAgendaPaxtel: false,
  isAgendaPerso: true,
}
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const linkRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
const phoneFormatMode = /(\d{2})/g

const Profils: React.FC = () => {
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues)
  const [isViewMode, setViewMode] = useState<boolean>(true)
  const [focusPhone, setFocusPhone] = useState<boolean>(false)
  const [isSubmitted, setSubmitted] = useState<boolean>(false)
  const [getInitData, { loading }] = useLazyQuery(ME_QUERY)
  const [openedCGU, openCGU] = useState<boolean>(false)
  const [updateProfils, { loading: updateLOAD }] = useMutation(UPDATE_PROFILS)
  const history = useHistory()
  const [presentLoading, dismissLoading] = useIonLoading()

  const handleClick = (): void => {
    StorageUtils.clear()
    history.push(`/login`)
  }

  const toasterSuccess = useToast({
    text: 'Modifications avec succès !',
    position: 'bottom',
  })

  const toasterFailed = useToast({
    text: "Une erreur s'est produite, si elle persiste contacter l'admin",
    position: 'bottom',
  })

  useEffect(() => {
    getInitData().then((res) => {
      if (res.data?.me) {
        const {
          email,
          firstname,
          lastname,
          phone,
          //  isAgendaPaxtel,
          //  isAgendaPerso,
          urlAgenda,
        } = res.data.me
        setFormValues({
          email: email || '',
          firstname: firstname?.toUpperCase() || '',
          lastname: lastname || '',
          phone: phone || '',
          //    isAgendaPaxtel: isAgendaPaxtel || false,
          //    isAgendaPerso: isAgendaPerso || false,
          urlAgenda: urlAgenda || '',
          urlAgendaTemp: urlAgenda || '',
        })
      }
    })
  }, [getInitData])

  /* const changeAgendaPerso = (): void => {
    const current = formValues.isAgendaPerso

    if (current) {
      setFormValues((prevValues) => ({
        ...prevValues,
        urlAgenda: '',
      }))
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        urlAgenda: prevValues.urlAgendaTemp,
      }))
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      isAgendaPerso: !current,
      isAgendaPaxtel: false,
    }))
  }

  const changeAgendaPaxtel = (): void => {
    const current = formValues.isAgendaPaxtel
    if (!current) {
      setFormValues((prevValues) => ({
        ...prevValues,
        isAgendaPerso: false,
      }))
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      isAgendaPaxtel: !current,
      urlAgenda: '',
    }))
  } */

  useEffect(() => {
    if (loading || updateLOAD) {
      presentLoading()
    } else {
      dismissLoading()
    }
    return () => {
      dismissLoading()
    }
  }, [loading, presentLoading, dismissLoading, updateLOAD])

  const handleInputChange = (
    ev: CustomEvent<InputChangeEventDetail>,
    field: string,
  ): void => {
    const { value } = ev.target as HTMLInputElement
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }))
  }
  const viewMode = (): void => {
    if (!isViewMode) {
      clickEdit()
    } else {
      setViewMode(false)
    }
  }
  const clickEdit = (): void => {
    setSubmitted(true)
    if (
      (formValues.email &&
        isEmailValid(formValues.email) &&
        formValues.firstname &&
        formValues.lastname &&
        !formValues.urlAgenda) ||
      (formValues.email &&
        isEmailValid(formValues.email) &&
        formValues.firstname &&
        formValues.lastname &&
        formValues.urlAgenda &&
        isLinkValid(formValues.urlAgenda))
    ) {
      update()
    }
  }

  const update = async (): Promise<void> => {
    try {
      const result = await updateProfils({
        variables: {
          data: {
            firstname: formValues.firstname.toUpperCase(),
            lastname: `${formValues.lastname
              ?.toString()
              .charAt(0)
              .toUpperCase()}${formValues.lastname?.toString().slice(1)}`,
            phone: formValues.phone,
            email: formValues.email,
            urlAgenda: formValues.urlAgenda,
            isAgendaPaxtel: formValues.isAgendaPaxtel,
            isAgendaPerso: formValues.isAgendaPerso,
          },
        },
      })

      const { data } = result
      if (data?.updateMe.data?.id) {
        setSubmitted(false)
        setViewMode(true)
        toasterSuccess()
        StorageUtils.set(
          'userData',
          data?.updateMe?.data?.attributes,
        )
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error #1', e)
      toasterFailed()
      throw e
    }
  }

  const isEmailValid = (email: string): boolean => {
    return emailRegex.test(email)
  }

  const isLinkValid = (link: string): boolean => {
    return linkRegex.test(link)
  }

  const handleBack = (): void => {
    setViewMode(true)
  }
  const handleRequestPermisssion = () => {
    requestPermission().then(() => {
      // get token
      requestForToken()
    })
  }

  return (
    <ProfilsWrapper>
      <ModalLayout title="Profil" classNameColor="header-green">
        <div className="tab-profil">
          <form className="create--profils">
            <div className={`profiles ${isViewMode && 'view-mode'}`}>
              <IonRow>
                <IonCol size="12">
                  {!isViewMode && (
                    <IonButton
                      className="inner-back-button"
                      fill="clear"
                      color="medium"
                      onClick={handleBack}
                    >
                      <IonIcon slot="start" icon={arrowBack} /> Retour
                    </IonButton>
                  )}
                  <IonItem className="ion-align-items-end">
                    <IonImg
                      className="profiles--image"
                      src="/assets/images/picto-info.svg"
                      alt="Vos informations"
                    />
                    <h3 className="profile-title text-green">
                      Vos informations
                    </h3>
                  </IonItem>
                </IonCol>

                <IonCol size="12">
                  <IonItem>
                    <IonInput
                      type="text"
                      disabled={isViewMode}
                      label="Nom"
                      labelPlacement="floating"
                      value={
                        !isViewMode
                          ? formValues.firstname.toLocaleUpperCase()
                          : isViewMode && !formValues.firstname
                          ? '-'
                          : formValues.firstname
                      }
                      onIonInput={(e) => handleInputChange(e, 'firstname')}
                    />
                  </IonItem>

                  {isSubmitted && !formValues.firstname && (
                    <IonText color="danger">Le champ est obligatoire</IonText>
                  )}
                </IonCol>

                <IonCol size="12">
                  <IonItem>
                    <IonInput
                      type="text"
                      disabled={isViewMode}
                      label="Prénom"
                      labelPlacement="floating"
                      value={
                        !isViewMode
                          ? `${formValues.lastname
                              ?.toString()
                              .charAt(0)
                              .toUpperCase()}${formValues.lastname
                              ?.toString()
                              .slice(1)}`
                          : isViewMode && !formValues.lastname
                          ? '-'
                          : `${formValues.lastname
                              ?.toString()
                              .charAt(0)
                              .toUpperCase()}${formValues.lastname
                              ?.toString()
                              .slice(1)}`
                      }
                      onIonInput={(e) => handleInputChange(e, 'lastname')}
                    />
                  </IonItem>
                  {isSubmitted && !formValues.lastname && (
                    <IonText color="danger">Le champ est obligatoire</IonText>
                  )}
                </IonCol>

                <IonCol size="12">
                  <IonItem>
                    <IonInput
                      label="Téléphone "
                      disabled={isViewMode}
                      onFocus={() => {
                        setFocusPhone(true)
                      }}
                      onBlur={() => {
                        setFocusPhone(false)
                      }}
                      labelPlacement="floating"
                      type={focusPhone ? 'number' : 'text'}
                      value={
                        !isViewMode
                          ? focusPhone
                            ? formValues.phone
                            : formValues.phone.replace(phoneFormatMode, '$1 ')
                          : isViewMode && !formValues.phone
                          ? '-'
                          : formValues.phone.replace(phoneFormatMode, '$1 ')
                      }
                      onIonInput={(e) => handleInputChange(e, 'phone')}
                    />
                  </IonItem>
                </IonCol>

                <IonCol size="12" className="ion-padding-bottom">
                  <IonItem>
                    <IonInput
                      label="Adresse email"
                      readonly
                      disabled={isViewMode}
                      labelPlacement="floating"
                      className="transmission-email"
                      value={
                        !isViewMode
                          ? formValues.email
                          : isViewMode && !formValues.email
                          ? '-'
                          : formValues.email
                      }
                      onIonInput={(e) => handleInputChange(e, 'email')}
                    />
                  </IonItem>
                  {isSubmitted && !formValues.email && (
                    <IonText color="danger">Le champ est obligatoire</IonText>
                  )}
                  {(isSubmitted && !formValues.email) ||
                    (formValues.email && !isEmailValid(formValues.email) && (
                      <IonText color="danger">
                        {formValues.email && !isEmailValid(formValues.email)
                          ? 'Format email invalide'
                          : 'Le champ est obligatoire'}
                      </IonText>
                    ))}
                </IonCol>
              </IonRow>
            </div>

            <div className={`agenda ${isViewMode && 'view-mode'}`}>
              <IonRow>
                <IonCol size="12">
                  <IonItem className="ion-align-items-center">
                    <IonImg
                      className="profiles--image"
                      src="/assets/images/calender.svg"
                      alt="Vos Agenda"
                    />
                    <h3 className="profile-title text-green">Votre agenda</h3>
                  </IonItem>
                </IonCol>
                {/*
                <IonCol size="12" className="ion-no-padding">
                  <IonItem className="item-check">
                    <IonLabel className="label-check">
                      Je souhaite utiliser l’agenda Paxtel (par défault)
                    </IonLabel>
                    <IonCheckbox
                      className="priority-check"
                      disabled={isViewMode}
                      slot="start"
                      checked={formValues.isAgendaPaxtel}
                      onIonChange={changeAgendaPaxtel}
                    />
                  </IonItem>
                </IonCol>

                <IonCol size="12" className="ion-no-padding">
                  <IonItem className="item-check">
                    <IonLabel className="label-check">
                      Je souhaite utiliser mon agenda personnel{' '}
                    </IonLabel>
                    <IonCheckbox
                      className="priority-check"
                      disabled={isViewMode}
                      slot="start"
                      checked={formValues.isAgendaPerso}
                      onIonChange={changeAgendaPerso}
                    />
                  </IonItem>
                        </IonCol> */}

                <IonCol size="12" className="ion-padding-bottom">
                  <IonItem>
                    <IonInput
                      type="text"
                      label="Lien de votre agenda"
                      labelPlacement="floating"
                      disabled={isViewMode}
                      placeholder="https://"
                      value={formValues.urlAgenda}
                      onIonInput={(e) => handleInputChange(e, 'urlAgenda')}
                    />
                  </IonItem>
                  {isSubmitted &&
                    formValues.urlAgenda &&
                    !isLinkValid(formValues.urlAgenda) && (
                      <IonText color="danger">
                        Le format du lien est invalide
                      </IonText>
                    )}
                </IonCol>
              </IonRow>
              <IonFab slot="fixed" vertical="top" horizontal="end">
                <IonFabButton onClick={handleRequestPermisssion}>
                  <IonIcon icon={notificationsCircleOutline} />
                </IonFabButton>
              </IonFab>
            </div>

            <IonRow onClick={() => openCGU(true)}>
              <IonCol size="12">
                <IonText className="ion-text-center">
                  <p className="conditions">
                    Conditions générales d’utilisation{' '}
                  </p>
                </IonText>
              </IonCol>
            </IonRow>

            <IonRow className="fixed-button ion-no-padding">
              <IonCol className="ion-no-padding">
                <IonButton
                  color="primary"
                  expand="block"
                  size="large"
                  fill="outline"
                  className="logout"
                  onClick={handleClick}
                >
                  Se déconnecter
                </IonButton>
              </IonCol>
              <IonCol className="ion-no-padding" onClick={viewMode}>
                <IonButton expand="block" size="large">
                  {isViewMode
                    ? 'Modifier mes informations'
                    : 'Sauvegarder mes modifications '}
                </IonButton>
              </IonCol>
            </IonRow>
          </form>
        </div>
        <ComponentModal
          isOpen={openedCGU}
          title="CGU"
          onCancel={() => openCGU(false)}
        >
          <Cgu />
        </ComponentModal>
      </ModalLayout>
    </ProfilsWrapper>
  )
}

const ProfilsWrapper = styled(IonPage)`
  .profils {
    position: fixed;
    width: 100%;
    z-index: 9999;
    right: 0;
    top: 104px;
    padding: 15px 0 10px;
    border-radius: 24px 24px 0 0;
    background: var(--ion-color-main-background);
  }

  .profils-content.no-map {
    margin-top: 40px;
  }

  .fixed-button {
    position: sticky;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--ion-color-main-background);
    z-index: 1000;
    width: 100%;
    background-color: #f7f7f7;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
  }
`
export default Profils
