import { IonIcon, IonItem, IonLabel, IonText } from '@ionic/react'
import { format } from 'date-fns'
import { chevronForwardOutline } from 'ionicons/icons'
import { FC } from 'react'
import { useHistory } from 'react-router'
import { UsagerNoteEntity } from 'src/models/gql/graphql'
import './NoteListItem.css'

export interface INoteListItemProps {
  note: UsagerNoteEntity
  usagerId: string
}

const NoteListItem: FC<INoteListItemProps> = ({
  note,
  usagerId,
}: INoteListItemProps) => {
  const history = useHistory()

  return (
    <IonItem
      className="note_list_item note_list_item-profil"
      onClick={() =>
        history.replace(`/main/usagers/view/${usagerId}/notes/view/${note.id}`)
      }
    >
      <div className="wrap-note">
        <IonLabel>
          <h3>{note?.attributes?.noteType?.data?.attributes?.name}</h3>
        </IonLabel>
        <div className="wrap-note-details">
          <div style={{ minWidth: '100px' }}>
            <svg className="feather small">
              <use href="/assets/icon/feather-sprite.svg#users" />
            </svg>
            <IonText className="name">
              {note?.attributes?.author?.data?.attributes?.lastname || '-'}{' '}
              {note?.attributes?.author?.data?.attributes?.firstname || '-'}
            </IonText>
          </div>
          <div>
            <svg className="feather small">
              <use href="/assets/icon/feather-sprite.svg#calendar" />
            </svg>
            <IonText className="age">
              {format(new Date(note?.attributes?.noteDate||note?.attributes?.createdAt), 'dd/MM/yyyy')}
            </IonText>
          </div>
        </div>
        <div className="wrap-note-desc">{note.attributes?.description}</div>

        <div className="notes-icon">
          <IonIcon
            icon={chevronForwardOutline}
            size="large"
            color="danger"
            className="action-arrow"
          />
        </div>
      </div>
    </IonItem>
  )
}

export default NoteListItem
