import { ApolloProvider } from '@apollo/client'
import { IonApp, setupIonicReact, useIonAlert } from '@ionic/react'
import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import store from './stores/store'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/padding.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import './theme/styles.css'
import { onMessageListener } from './utils/firebase'
/* Theme variables */
import './theme/variables.css'

import Routes from './Routes'
import { useServiceWorker } from './hooks'
import ApolloService from './services/apollo.service'

const client = ApolloService.getInstance()

setupIonicReact()

const App: React.FC = () => {
  const [notification, setNotification] = useState({ title: '', body: '' })
  const [presentAlert, dismissAlert] = useIonAlert()
  const { waitingWorker, showReload, reloadPage } = useServiceWorker()
  useEffect(() => {
    if (notification?.title) {
      console.log('notifications', notification)
    }
  }, [notification])

  // decides when to show the toast
  /* useEffect(() => {
    if (showReload && waitingWorker) {
      presentAlert('Une nouvelle version de l\'application est disponible veuillez recharger');
    } else dismissAlert();
  }, [waitingWorker, showReload, reloadPage]) ; */

  onMessageListener()
    .then((payload: any) => {
      console.log('got payload', payload)
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      })
    })
    .catch((err) => console.log('failed: ', err))

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <IonApp>
          <Routes />
        </IonApp>
      </ApolloProvider>
    </Provider>
  )
}

export default App
